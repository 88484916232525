<template>
    <div>
        <div class="card-toolbar mb-5">
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder">
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>
        <!--begin::customer-->
        <div class="card card-custom mb-5" v-if="showAdvancedSearch">

            <div class="card-body">

                <!-- Filter -->
                <div>
                    <div class="m-form m-form--fit m--margin-bottom-20" v-if="showAdvancedSearch">
                        <div class="row pb-5">
                            <div class="col-lg-6 mt-2 mb-2">
                                <label>{{$t('cost_transactions.accounts')}}</label>
                                <treeselect
                                        :options="accounts"
                                        :load-options="loadOptions"
                                        :multiple="false"
                                        :value="filters.account_id"
                                        @input="updateValue('account_id',$event)"
                                        :searchable="true"
                                        :show-count="true"
                                        :no-children-text="$t('No_sub_options')"
                                        :no-options-text="$t('No_options_available')"
                                        :no-results-text="$t('No_results_found')"
                                        :placeholder="$t('Select')">
                                </treeselect>
                            </div>
                            <div class="col-lg-6 mt-2 mb-2">
                                <label>{{$t('cost_transactions.cost_centers')}}</label>
                                <treeselect
                                        :options="cost_center_list"
                                        :load-options="loadOptions"
                                        :multiple="false"
                                        :value="filters.cost_center_id"
                                        @input="updateValue('cost_center_id',$event)"
                                        :searchable="true"
                                        :show-count="true"
                                        :no-children-text="$t('No_sub_options')"
                                        :no-options-text="$t('No_options_available')"
                                        :no-results-text="$t('No_results_found')"
                                        :placeholder="$t('Select')">
                                </treeselect>
                            </div>


                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="from_date">{{$t('cost_transactions.from_date')}}</label>
                                <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                            </div>
                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="to_date">{{$t('cost_transactions.to_date')}}</label>
                                <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                            </div>


                            <div class="form-group d-inline-flex col-md-6 mt-10">
                                <button type="button" @click="doFilter" class="btn btn-primary my-auto mb-0 mr-2" id="m_search">
                              <span><i class="fas fa-search"></i>
                                  <span>{{$t('search')}}</span>
                              </span>
                                </button>
                                <button type="button" @click="resetFilter" class="btn btn-danger my-auto mb-0 mr-2" id="m_reset">
                              <span><i class="fas fa-trash-restore"></i>
                                  <span>{{$t('reset_search')}}</span>
                              </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Filter -->
            </div>
        </div>
        <div class="card card-custom">

            <div class="card-body">
                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

                    <template slot="transaction" slot-scope="props">
                        <div>
                            <div><span>{{ props.row.created_at}}</span></div>
                            <div><span>#{{(props.row.cost_center_id)}}-</span><span>{{ props.row.name}}</span></div>
                            <div><span>#{{ props.row.account_id }} </span>-{{ props.row.type_type }}- #<span>{{  props.row.type_id }}</span></div>
                        </div>
                    </template>
                    <template slot="debit" slot-scope="props">
                        <div><span>{{ props.row.debit }}</span></div>
                    </template>
                    <template slot="credit" slot-scope="props">
                        <div><span>{{ props.row.credit }}</span></div>
                    </template>
                    <template slot="actions" slot-scope="props">
                        <v-btn icon v-if="$can('cost_centers.update')" color="pink" v-b-tooltip.hover :title="$t('edit')" :to="`/sales/cost-center/create/${props.row.type_id}`">
                            <v-icon small class="mr-2 text-info">mdi-pencil</v-icon>
                        </v-btn>
                        <v-icon small v-if="$can('cost_centers.delete')" class="text-danger" v-b-tooltip.hover :title="$t('delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>
                    </template>
                </v-server-table>

            </div>
        </div>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index-cost_transactions",
        components: {},
        data() {
            return {
                mainRoute: 'sales/cost-center/transactions',
                deleteTransactionRoute: 'sales/cost-center/transaction/delete',
                mainRouteDependency: 'base/dependency',
                showAdvancedSearch: false,

                filter: {
                    sortBy: 'id',
                },
                filters: {
                    account_id: null,
                    cost_center_id: null,
                    to_date: null,
                    from_date: null,
                },
                columns: ['transaction', 'debit', 'credit', 'actions'],
                data: [],
                users: [],
            }
        },
        computed: {
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        transaction: that.$t('cost_transactions.transaction'),
                        debit: that.$t('cost_transactions.debit'),
                        credit: that.$t('cost_transactions.credit'),
                        actions: that.$t('actions'),
                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: false,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.perPage,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(`${that.mainRoute}`, {..._params});

                    },
                    responseAdapter(resp) {
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },

        methods: {
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.account_id = null;
                this.filters.cost_center_id = null;
                this.filters.to_date = null;
                this.filters.from_date = null;

                this.$refs.table.refresh();
            },
            getFetch() {
                this.$refs.table.refresh();
            },

            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },

            actionDelete(item) {
                ApiService.delete(`${this.deleteTransactionRoute}/${item.id}`).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },

            getCostCenterList() {
                ApiService.get(this.mainRouteDependency + "/cost_centers").then((response) => {
                    this.cost_center_list = response.data.data;
                });
            },

            getAccounts() {
                ApiService.get(this.mainRouteDependency + "/account_parents").then((response) => {
                    this.accounts = response.data.data;
                });
            },

            loadOptions() {
            },
            updateValue(type, value) {
                type == 'account_id' ? this.filters.account_id = value : this.filters.cost_center_id = value;
            },

            showDetails(id) {
                this.$router.push({name: 'cost_transactions.transaction', params: {id: id}});
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.cost_centers"), route:'/accounting/cost-centers'}, {title: this.$t("MENU.cost_transactions")}]);
            this.getAccounts();
            this.getCostCenterList();
        },
    };
</script>
